define("discourse/plugins/discourse-calendar/discourse/raw-views/event-date-container", ["exports", "@ember/object", "discourse/lib/raw-render-glimmer", "discourse/plugins/discourse-calendar/discourse/components/event-date", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _object, _rawRenderGlimmer, _eventDate, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _object.default {
    get html() {
      return (0, _rawRenderGlimmer.default)(this, "span.event-date-container-wrapper", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
      /*
        <EventDate @topic={{@data.topic}} />
      */
      {
        "id": "w7pYN+Gf",
        "block": "[[[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[\"@data\"],false,[]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/raw-views/event-date-container.js",
        "scope": () => [_eventDate.default],
        "isStrictMode": true
      }), (0, _templateOnly.default)(undefined, undefined)), {
        topic: this.topic
      });
    }
  }
  _exports.default = _default;
});